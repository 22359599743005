import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import Avatar from "@/components/Avatar";
import { Tag } from "antd-mobile";
import { ResultStatusEnum } from "@/enums/result_status.enum";

interface ParticipantInfoProps {
  result: ParticipantResultDto;
  useInitials?: boolean;
}

const isSpecialStatus = (status: ResultStatusEnum) =>
  [ResultStatusEnum.DQ, ResultStatusEnum.DNF, ResultStatusEnum.DNS].includes(
    status
  );

const getInitials = (name: string): string =>
  name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");

export default function ParticipantInfo({
  result,
  useInitials = false,
}: Readonly<ParticipantInfoProps>) {
  const avatarTitle = useInitials
    ? getInitials(result.participant_name)
    : result?.place_age_group?.toString() || "";

  return (
    <div className="flex items-center mb-4 min-w-0">
      <div>
        <Avatar
          color={result?.participant_gender}
          title={isSpecialStatus(result.status) ? result.status : avatarTitle}
          circle={useInitials ? 0 : result.percent_completed}
          dot={
            !isSpecialStatus(result.status) &&
            result.percent_completed < 100 &&
            !useInitials
          }
          size="small"
        />
      </div>

      <div className="flex flex-col w-full min-w-0 ml-3">
        <h2 className="text-lg w-full max-w-full flex items-center gap-2 dark:text-white">
          <span className="truncate">{result.participant_name}</span>
          <Tag className="dark:text-gray-800 shrink-0">{result.number}</Tag>
        </h2>
        <p className="text-gray-500 dark:text-gray-400 text-xs w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
          {result?.contest_name} {result?.age_group_name}{" "}
        </p>
        {result?.disqualification_reason && (
          <div className="mt-1">
            <Tag color="#dc4446" fill="outline">
              {result?.disqualification_reason}
            </Tag>
          </div>
        )}
      </div>
    </div>
  );
}
